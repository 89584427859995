import Head from "next/head";
import { useCurrentUser, FetchCurrentUserOptions } from "../../lib/user";
import { authUrl } from "../../lib/url";
import Flex from "../Flex";

const HTML_ATTRIBUTES = [
  "accept",
  "acceptCharset",
  "action",
  "allowFullScreen",
  "allowTransparency",
  "alt",
  "as",
  "async",
  "autoComplete",
  "autoFocus",
  "autoPlay",
  "capture",
  "cellPadding",
  "cellSpacing",
  "charSet",
  "challenge",
  "checked",
  "cite",
  "classID",
  "cols",
  "colSpan",
  "content",
  "controls",
  "coords",
  "crossOrigin",
  "data",
  "dateTime",
  "default",
  "defer",
  "disabled",
  "download",
  "encType",
  "form",
  "formAction",
  "formEncType",
  "formMethod",
  "formNoValidate",
  "formTarget",
  "frameBorder",
  "headers",
  "height",
  "high",
  "href",
  "hrefLang",
  "htmlFor",
  "httpEquiv",
  "integrity",
  "keyParams",
  "keyType",
  "kind",
  "label",
  "list",
  "loop",
  "low",
  "manifest",
  "marginHeight",
  "marginWidth",
  "max",
  "maxLength",
  "media",
  "mediaGroup",
  "method",
  "min",
  "minLength",
  "multiple",
  "muted",
  "name",
  "nonce",
  "noValidate",
  "open",
  "optimum",
  "pattern",
  "placeholder",
  "playsInline",
  "poster",
  "preload",
  "readOnly",
  "rel",
  "required",
  "reversed",
  "rows",
  "rowSpan",
  "sandbox",
  "scope",
  "scoped",
  "scrolling",
  "seamless",
  "selected",
  "shape",
  "size",
  "sizes",
  "span",
  "src",
  "srcDoc",
  "srcLang",
  "srcSet",
  "start",
  "step",
  "summary",
  "target",
  "type",
  "useMap",
  "value",
  "width",
  "wmode",
  "wrap",
];

const DOM_ATTRIBUTES = [
  "children",
  "dangerouslySetInnerHTML",
  // Clipboard Events
  "onCopy",
  "onCopyCapture",
  "onCut",
  "onCutCapture",
  "onPaste",
  "onPasteCapture",
  // Composition Events
  "onCompositionEnd",
  "onCompositionEndCapture",
  "onCompositionStart",
  "onCompositionStartCapture",
  "onCompositionUpdate",
  "onCompositionUpdateCapture",
  // Focus Events
  "onFocus",
  "onFocusCapture",
  "onBlur",
  "onBlurCapture",
  // Form Events
  "onChange",
  "onChangeCapture",
  "onBeforeInput",
  "onBeforeInputCapture",
  "onInput",
  "onInputCapture",
  "onReset",
  "onResetCapture",
  "onSubmit",
  "onSubmitCapture",
  "onInvalid",
  "onInvalidCapture",
  // Image Events
  "onLoad",
  "onLoadCapture",
  "onError",
  "onErrorCapture",
  // Keyboard Events
  "onKeyDown",
  "onKeyDownCapture",
  /** @deprecated */
  "onKeyPress",
  /** @deprecated */
  "onKeyPressCapture",
  "onKeyUp",
  "onKeyUpCapture",
  // Media Events
  "onAbort",
  "onAbortCapture",
  "onCanPlay",
  "onCanPlayCapture",
  "onCanPlayThrough",
  "onCanPlayThroughCapture",
  "onDurationChange",
  "onDurationChangeCapture",
  "onEmptied",
  "onEmptiedCapture",
  "onEncrypted",
  "onEncryptedCapture",
  "onEnded",
  "onEndedCapture",
  "onLoadedData",
  "onLoadedDataCapture",
  "onLoadedMetadata",
  "onLoadedMetadataCapture",
  "onLoadStart",
  "onLoadStartCapture",
  "onPause",
  "onPauseCapture",
  "onPlay",
  "onPlayCapture",
  "onPlaying",
  "onPlayingCapture",
  "onProgress",
  "onProgressCapture",
  "onRateChange",
  "onRateChangeCapture",
  "onSeeked",
  "onSeekedCapture",
  "onSeeking",
  "onSeekingCapture",
  "onStalled",
  "onStalledCapture",
  "onSuspend",
  "onSuspendCapture",
  "onTimeUpdate",
  "onTimeUpdateCapture",
  "onVolumeChange",
  "onVolumeChangeCapture",
  "onWaiting",
  "onWaitingCapture",
  // MouseEvents
  "onAuxClick",
  "onAuxClickCapture",
  "onClick",
  "onClickCapture",
  "onContextMenu",
  "onContextMenuCapture",
  "onDoubleClick",
  "onDoubleClickCapture",
  "onDrag",
  "onDragCapture",
  "onDragEnd",
  "onDragEndCapture",
  "onDragEnter",
  "onDragEnterCapture",
  "onDragExit",
  "onDragExitCapture",
  "onDragLeave",
  "onDragLeaveCapture",
  "onDragOver",
  "onDragOverCapture",
  "onDragStart",
  "onDragStartCapture",
  "onDrop",
  "onDropCapture",
  "onMouseDown",
  "onMouseDownCapture",
  "onMouseEnter",
  "onMouseLeave",
  "onMouseMove",
  "onMouseMoveCapture",
  "onMouseOut",
  "onMouseOutCapture",
  "onMouseOver",
  "onMouseOverCapture",
  "onMouseUp",
  "onMouseUpCapture",
  // Selection Events
  "onSelect",
  "onSelectCapture",
  // Touch Events
  "onTouchCancel",
  "onTouchCancelCapture",
  "onTouchEnd",
  "onTouchEndCapture",
  "onTouchMove",
  "onTouchMoveCapture",
  "onTouchStart",
  "onTouchStartCapture",
  // Pointer Events
  "onPointerDown",
  "onPointerDownCapture",
  "onPointerMove",
  "onPointerMoveCapture",
  "onPointerUp",
  "onPointerUpCapture",
  "onPointerCancel",
  "onPointerCancelCapture",
  "onPointerEnter",
  "onPointerEnterCapture",
  "onPointerLeave",
  "onPointerLeaveCapture",
  "onPointerOver",
  "onPointerOverCapture",
  "onPointerOut",
  "onPointerOutCapture",
  "onGotPointerCapture",
  "onGotPointerCaptureCapture",
  "onLostPointerCapture",
  "onLostPointerCaptureCapture",
  // UI Events
  "onScroll",
  "onScrollCapture",
  // Wheel Events
  "onWheel",
  "onWheelCapture",
  // Animation Events
  "onAnimationStart",
  "onAnimationStartCapture",
  "onAnimationEnd",
  "onAnimationEndCapture",
  "onAnimationIteration",
  "onAnimationIterationCapture",
  // Transition Events
  "onTransitionEnd",
  "onTransitionEndCapture",
];

function htmlAttributes<T>(props: unknown): React.HTMLAttributes<T> {
  return Object.keys(props).reduce((attributes, key) => {
    if (
      HTML_ATTRIBUTES.includes(key) ||
      DOM_ATTRIBUTES.includes(key) ||
      key.startsWith("aria-")
    ) {
      attributes[key] = props[key];
    }

    return attributes;
  }, {});
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  currentUser?: FetchCurrentUserOptions;
  app?: boolean;
  header?: React.ReactNode;
}

export default function Page(props: Props): JSX.Element {
  const { data, isLoading } = useCurrentUser(props.currentUser);

  return (
    <div {...htmlAttributes<HTMLDivElement>(props)} className="page">
      <style jsx>{`
        @keyframes fadeIn {
          0% {
            top: 16px;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0);
          }

          100% {
            top: 0;
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }

        .page {
          display: flex;
          flex-direction: column;
          color: white;
          min-width: 320px; // small iphone
          min-height: 100%;
          height: 100%;
          overflow: hidden;
        }

        .header {
          padding: 16px;
          font-size: 16px;
          height: 48px;
        }

        .navigation {
          display: flex;
          min-width: 0;
          max-width: 400px;
          margin: 0px auto;
          padding: 0 8px;
        }

        .header a {
          color: white;
          text-decoration: none;
        }

        .header a + a {
          margin-left: 16px;
        }

        .header a:first-child {
          border-bottom: 4px currentColor solid;
        }
      `}</style>
      <style jsx>{`
        :global(body) {
          ${props.app
            ? `
            animation: fadeIn cubic-bezier(0.165, 0.84, 0.44, 1) 300ms;
            background-color: rgba(0, 0, 0, 0.5);
            `
            : `background-color: black;`}
        }
      `}</style>
      <Head>
        <title>Dabounce Beta</title>
      </Head>
      {props.header !== undefined ? (
        props.header
      ) : (
        <div className="header">
          <div className="navigation">
            {!props.app && <a>Home</a>}
            {!data?.currentUser && !isLoading ? (
              <>
                <Flex />
                {/* <a href={authUrl(props.app ? "/login?transfer=1" : "/login")}>
                Login
              </a> */}
              </>
            ) : (
              <>
                <Flex />
                {!props.app && (
                  <a
                    href={authUrl("/logout")}
                    onClick={(event) => {
                      if (globalThis.app?.logout) {
                        event.preventDefault();
                        globalThis.app.logout();
                      }
                    }}
                  >
                    Logout
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
}
