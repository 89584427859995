export default function Flex(props: {
  children?: React.ReactNode;
  grow?: number;
  shrink?: number;
  basis?: string;
  direction?: "row" | "column";
  alignItems?: "center";
}): JSX.Element {
  return (
    <div className="flex">
      <style jsx>{`
        .flex {
          display: flex;
          flex: ${props.grow !== undefined ? props.grow : 1}
            ${props.shrink !== undefined ? props.shrink : 1}
            ${props.basis !== undefined ? props.basis : "auto"};
          flex-direction: ${props.direction || "row"};
          align-items: ${props.alignItems || "initial"};
          min-width: 0; // allow text truncation by default
        }
      `}</style>
      {props.children}
    </div>
  );
}
