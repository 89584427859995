import React, { forwardRef } from "react";
import { Button as BaseButton } from "reakit";
import { Button as GeistButton, Tooltip } from "@geist-ui/react";

type As<Props = any> = React.ElementType<Props>;

type GenericIcon = React.ComponentType<{
  size?: number;
  color?: string;
}>;

export default forwardRef(function Button(
  props: {
    as?: As;
    Icon?: GenericIcon;
    icon?: React.ComponentProps<GenericIcon>;
    iconOnly?: boolean;
    tooltip?: boolean | Omit<React.ComponentProps<typeof Tooltip>, "text">;
    title?: string;
    className?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    size?: "mini" | "small" | "medium" | "large";
    type?:
      | "default"
      | "secondary"
      | "success"
      | "warning"
      | "error"
      | "abort"
      | "secondary-light"
      | "success-light"
      | "warning-light"
      | "error-light";
    auto?: boolean;
    loading?: boolean;
    onClick?: () => void;
  },
  ref: any
): JSX.Element {
  const { children, iconOnly, icon, Icon, tooltip, ...baseProps } = props;

  const button = (
    <BaseButton
      ref={ref}
      as={iconOnly ? undefined : GeistButton}
      aria-label={props.title}
      {...baseProps}
    >
      {Icon && (
        <Icon
          size={24}
          color={props.disabled ? "#006cff55" : "#006cff"}
          {...icon}
        />
      )}
      {iconOnly ? null : children}
    </BaseButton>
  );

  if (tooltip || (iconOnly && typeof children === "string")) {
    return (
      <Tooltip
        text={props.title || children}
        placement="bottom"
        offset={2}
        enterDelay={props.disabled ? 50 : 1000} // 0ms too fast
        {...tooltip}
      >
        {button}
      </Tooltip>
    );
  }

  return button;
});
